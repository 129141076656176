import React, { useEffect, useRef, useState } from 'react'
import { Language, Menu, SettingAccountBox } from '@src/assets/icons'
import { useAppDispatch, useAppSelector } from '@src/hooks/redux'
import { routeBgColorCheck } from '@src/utils/routeBgColorCheck'
import { useRouter } from 'next/router'
import { checkStaffAuth } from '@src/utils/auth/checkAuthorized'
import AuthMenu from '../AuthMenu'
import { useResponsive } from '@src/hooks/useResponsive'
import { setIsSitemapOpen } from '@src/store/modules/commons'
import LanguageSettings from './languageSettings/LanguageSettings'
import * as S from './SubMenu.styled'
import { CustomToolTip } from '@src/components/commons/tooltip'
import { transform } from 'lodash'
import checkAccessToken from '@src/utils/auth/checkAccessToken'

type Props = {
  setSubMenuWidth: React.Dispatch<React.SetStateAction<number>>
}
const SubMenu = ({ setSubMenuWidth }: Props) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const isHoverHeader = useAppSelector((state) => state.commons.isHoverHeader)
  const newUserInfo = useAppSelector((state) => state.userInfo.userInfoMasking)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { isMobile } = useResponsive(768)
  const { isTablet } = useResponsive(1280)
  const menuRef = useRef<HTMLDivElement>(null)
  const hasAccessToken = checkAccessToken()

  const handleClickLanguageSettings = (e: null | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e === null) {
      setAnchorEl(null)
      return
    }
    setAnchorEl(e.currentTarget)
  }

  useEffect(() => {
    if (menuRef.current) {
      setSubMenuWidth(menuRef.current.scrollWidth)
    }
  }, [isHoverHeader])

  return (
    <S.SubMenuWrapper ref={menuRef}>
      {isTablet ? null : (
        <>
          <AuthMenu />
          <S.SubMenuSlice $bg_color={Boolean(isHoverHeader) ? '#1E1E1E' : routeBgColorCheck(router.asPath)} />
          {hasAccessToken && checkStaffAuth(newUserInfo.role) && (
            <>
              <button onClick={() => router.push('/management/main')}>
                <CustomToolTip message={`${newUserInfo.role}`} type="bottom">
                  <SettingAccountBox
                    style={{ transform: 'translateY(3px)' }}
                    fill={Boolean(isHoverHeader) ? '#1E1E1E' : routeBgColorCheck(router.asPath)}
                    width={40}
                    height={40}
                  />
                </CustomToolTip>
              </button>
              <S.SubMenuSlice $bg_color={Boolean(isHoverHeader) ? '#1E1E1E' : routeBgColorCheck(router.asPath)} />
            </>
          )}
          <button onClick={handleClickLanguageSettings}>
            <Language
              fill={Boolean(isHoverHeader) ? '#1E1E1E' : routeBgColorCheck(router.asPath)}
              width={40}
              height={40}
            />
          </button>
          <LanguageSettings
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            handleClose={() => handleClickLanguageSettings(null)}
          />
          <S.SubMenuSlice $bg_color={Boolean(isHoverHeader) ? '#1E1E1E' : routeBgColorCheck(router.asPath)} />
        </>
      )}
      <button id="menuIcon" onClick={() => dispatch(setIsSitemapOpen(true))}>
        <Menu
          width={isMobile ? 32 : 40}
          height={isMobile ? 32 : 40}
          fill={Boolean(isHoverHeader) || isMobile ? '#1E1E1E' : routeBgColorCheck(router.asPath)}
        />
      </button>
    </S.SubMenuWrapper>
  )
}

export default SubMenu
