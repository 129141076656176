import { useEffect, useState } from 'react'
import { useQuickMenuActive } from '@src/hooks/useQuickMenuActive'
import { useResponsive } from '@src/hooks/useResponsive'
import { Arrow_Upward, Charger, ContractEdit, DomainAdd, Me_Logo, WevLogo } from '@src/assets/icons'
import { useRouter } from 'next/router'
import parse from 'html-react-parser'
import { useTranslation } from 'next-i18next'
import * as S from './QuickMenu.styled'

const QuickMenu = () => {
  const { t } = useTranslation('layout')
  const router = useRouter()
  const { isAtTop, isActive } = useQuickMenuActive()
  const { isTablet } = useResponsive(1024)
  const [isChargerActive, setIsChargerActive] = useState(false)
  const [isWevActive, setIsWevActive] = useState(false)
  const QUICK_MENU_SHOW = [
    '/',
    '/wev',
    '/wev/BI',
    '/wev/service/app',
    '/wev/service/membership_card',
    '/wev/service/fee',
    '/wev/partnership',
  ]
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  useEffect(() => {
    setIsWevActive(false)
  }, [router.asPath])

  if (!QUICK_MENU_SHOW.includes(router.pathname)) {
    return null
  } else {
    return (
      <S.QuickMenu $isTop={isAtTop} $isActive={isActive}>
        <>
          <S.WevMenu
            $isActive={isWevActive}
            onMouseEnter={() => setIsWevActive(true)}
            onMouseLeave={() => setIsWevActive(false)}
          >
            <div>
              {isWevActive ? (
                <S.WevMenuWrapper>
                  <button
                    onClick={() => {
                      router.push('/wev/install_consult')
                    }}
                  >
                    <ContractEdit width={!isTablet ? 30 : 20} height={!isTablet ? 30 : 20} fill="#ffffff" />
                    <p>{t('quickMenu.충전기 설치')}</p>
                  </button>
                  <button
                    onClick={() => {
                      router.push('/wev/partnership/consult')
                    }}
                  >
                    <DomainAdd width={!isTablet ? 30 : 20} height={!isTablet ? 30 : 20} fill="#ffffff" />
                    <p>{t('quickMenu.사업 제휴')}</p>
                  </button>
                </S.WevMenuWrapper>
              ) : (
                <button onClick={() => setIsWevActive(true)}>
                  <WevLogo width={!isTablet ? 50 : 39} height={!isTablet ? 12 : 26} />
                </button>
              )}
            </div>
          </S.WevMenu>
          <S.ChargerMenu $isActive={isAtTop || isChargerActive}>
            {!isTablet && router.locale !== 'ko' && <div>{t('quickMenu.국내 최초')}</div>}
            <button
              onClick={() => {
                setIsChargerActive((prev) => !prev)
                router.push('/wev/product/public_7kW')
              }}
              onMouseEnter={() => setIsChargerActive(true)}
              onMouseLeave={() => setIsChargerActive(false)}
              style={router.locale !== 'ko' ? { gap: '10px' } : {}}
            >
              <div>
                {router.locale === 'ko' ? (
                  <Me_Logo width={!isTablet ? 42 : 32} height={!isTablet ? 42 : 32} />
                ) : (
                  <Charger width={!isTablet ? 32 : 19} height={!isTablet ? 58 : 36} />
                )}
              </div>
              {(isAtTop || isChargerActive) && (
                <div className="quick_charger_item">
                  <p>{parse(t('quickMenu.title'))}</p>
                  <p style={router.locale === 'ko' ? { textAlign: 'center' } : {}}>{parse(t('quickMenu.desc'))}</p>
                </div>
              )}
            </button>
          </S.ChargerMenu>
          <button onClick={scrollToTop}>
            <Arrow_Upward width={!isTablet ? 30 : 20} height={!isTablet ? 30 : 20} fill={'#1E1E1E'} />
          </button>
        </>
      </S.QuickMenu>
    )
  }
}

export default QuickMenu
