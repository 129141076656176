import getConfigs from './config.common'

const publicUrl = 'http://localhost:8080/'
const baseUrl = 'http://localhost:65421/v1/'
const mode = 'local'
const recaptcha = '6Le4KEEiAAAAAFiiNgiaM4-s8Z--y4DR-Q9DZUwg'

const configLocal = getConfigs({
  publicUrl,
  baseUrl,
  mode,
  recaptcha,
})

export default configLocal
