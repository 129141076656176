import styled from 'styled-components'

export const QuickMenu = styled.aside<{ $isTop: boolean; $isActive: boolean }>`
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  color: ${({ theme }) => theme.wev_color.primary};
  z-index: 30;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    width: 62px;
    height: ${({ $isTop }) => ($isTop ? '0px' : '62px')};
    overflow: hidden;

    ${({ theme }) => theme.wev_font.Regular_12};
  }

  @media (min-width: 768px) {
    right: 30px;
    gap: 10px;
    > button {
      width: 90px;
      height: ${({ $isTop }) => ($isTop ? '0px' : '90px')};
      ${({ theme }) => theme.wev_font.Regular_18};
    }
  }
`

export const ChargerMenu = styled.div<{ $isActive: boolean }>`
  position: relative;
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: ${({ $isActive }) => ($isActive ? '220px' : '62px')};
    height: 62px;
    overflow: hidden;
    border-radius: 1000px;
    background-color: white;
    transition: width 0.3s ease-in-out;
    ${({ theme }) => theme.wev_font.Regular_12};
    .quick_charger_item {
      text-align: left;
      > p:nth-child(1) {
        white-space: nowrap;
        ${({ theme }) => theme.wev_font.sBold_16};
        color: ${({ theme }) => theme.wev_color.primary};
      }
      > p:nth-child(2) {
        white-space: nowrap;
        ${({ theme }) => theme.wev_font.Regular_10};
        color: ${({ theme }) => theme.wev_color.text};
        > span {
          ${({ theme }) => theme.wev_font.sBold_10};
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      width: ${({ $isActive }) => ($isActive ? '220px' : '62px')};
      height: 62px;
      border-radius: 1000px;
      padding: 1px;
      background: ${({ theme }) =>
        `linear-gradient(100deg, ${theme.wev_color.gradation_l}, ${theme.wev_color.gradation_r})`};
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      transition: width 0.3s ease-in-out;
    }
  }

  @media (min-width: 768px) {
    > button {
      box-shadow: 0px 0px 10px 0px #00000040;
      width: ${({ $isActive }) => ($isActive ? '280px' : '90px')};
      height: 90px;
      transition: width 0.3s ease-in-out;
      ${({ theme }) => theme.wev_font.Regular_18};
      &::before {
        width: ${({ $isActive }) => ($isActive ? '280px' : '90px')};
        height: 90px;
        transition: width 0.3s ease-in-out;
      }
      .quick_charger_item {
        text-align: left;

        > p:nth-child(1) {
          ${({ theme }) => theme.wev_font.sBold_16};
        }
        > p:nth-child(2) {
          ${({ theme }) => theme.wev_font.Regular_14};
          > span {
            ${({ theme }) => theme.wev_font.sBold_14};
          }
        }
      }
    }
    > div {
      position: absolute;
      top: -7px;
      right: 0px;
      padding: 2px 4px;
      background-color: #ea211a;
      border-radius: 100px;
      color: #ffffff;
      white-space: nowrap;
      ${({ theme }) => theme.wev_font.sBold_12};
      z-index: 1;
    }
  }
`

export const WevMenu = styled.div<{ $isActive: boolean }>`
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: ${({ $isActive }) => ($isActive ? '140px' : '62px')};
    transition: height 0.3s ease-in-out;
    border-radius: 1000px;
    background-color: ${({ theme }) => theme.wev_color.primary};
  }
  @media (min-width: 768px) {
    > div {
      width: 90px;
      height: ${({ $isActive }) => ($isActive ? '200px' : '90px')};
      transition: height 0.3s ease-in-out;
      border-radius: 1000px;
      background-color: ${({ theme }) => theme.wev_color.primary};
    }
  }
`

export const WevMenuWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    height: 100%;
    ${({ theme }) => theme.wev_font.Regular_10};
    color: white;
  }
  @media (min-width: 768px) {
    padding: 20px;
    > button {
      ${({ theme }) => theme.wev_font.sBold_14};
    }
  }
`
